import classNames from 'classnames'
import Link from 'next/link'

type CommonProps = {
  text: string
  small?: true
}

type ConditionalColorProps =
  | {
      // Outline can only be used when not disabled and not colored
      color?: never
      disabled?: never
      outline?: true
    }
  | {
      // Color can only be used when not disabled and not outlined
      color?: string
      disabled?: never
      outline?: never
    }
  | {
      // Disabled and outline and mutually exclusive, but the color can be set to pink when disabled
      color?: 'pink'
      disabled?: boolean
      outline?: never
    }

type LinkProps = { href?: string; external?: true; onClick?: () => void }

const Button = (props: CommonProps & LinkProps & ConditionalColorProps) => {
  const button = (
    <button
      className={classNames('font-semibold rounded-full h-[40px]', {
        // Texts
        'text-white': props.color !== undefined,
        'text-greyDarker': !props.color,

        'bg-white border-yellow border-2': props.outline,

        // Backgrounds
        'bg-yellow': !props.disabled && !props.outline && props.color === undefined,
        'bg-pink': !props.disabled && !props.outline && props.color === 'pink',
        'bg-bluePurple': !props.disabled && !props.outline && props.color === 'bluePurple',
        'bg-greenDark': !props.disabled && !props.outline && props.color === 'greenDark',
        'bg-blueDark': !props.disabled && !props.outline && props.color === 'blueDark',
        'bg-blueMedium': !props.disabled && !props.outline && props.color === 'blueMedium',

        // Disabled
        'bg-yellowLight': props.disabled && !props.color,
        'bg-pinkLight': props.disabled && props.color === 'pink',

        // Width
        'w-[280px]': props.small,
        'w-full': !props.small,
      })}
      disabled={props.disabled || (props.onClick === undefined && props.href === undefined)}
      onClick={props.onClick}
      // Arbitrary background color
      style={{ backgroundColor: !props.disabled && props.color && props.color !== 'pink' ? props.color : undefined }}
    >
      {props.text}
    </button>
  )

  if (props.href) {
    if (props.external) {
      return (
        <a href={props.href} target="_blank" rel="noreferrer">
          {button}
        </a>
      )
    }

    // Internal link
    return <Link href={props.href}>{button}</Link>
  }

  return button
}

export default Button
