import Head from 'next/head'
import { ReactElement } from 'react'
import InAppLayout from '../components/pages/in-app/InAppLayout'
import { IndexPage } from '../components/pages/in-app/Index/IndexPage'
import { NextPageWithLayout } from './_app'

// TODO move to /in-app
const Page: NextPageWithLayout = () => {
  return (
    <div>
      <Head>
        <title>myBooster</title>
      </Head>

      <IndexPage />
    </div>
  )
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <InAppLayout>{page}</InAppLayout>
}

export default Page
