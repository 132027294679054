import { InAppProduct } from '../../../../types/in-app/InAppProduct'
import { formatCentsAmountEuro } from '../../../../utils/formatCentsAmount'
import { postMessageTrack } from '../../../../utils/iframeUtils'
import Button from '../../../Button'
import Svg from '../../../Svg'
import Text from '../../../Text'

type Props = {
  onClick: () => void
  inAppProduct: InAppProduct
}

export default function IndexOffer(props: Props) {
  return (
    <div className="rounded-2xl flex flex-col" style={{ backgroundColor: props.inAppProduct.color }}>
      <div className="mt-3 mb-2">
        <Text align="center" color="white">
          Profitez jusqu’à{' '}
          <Text as="span" weight="bold">
            {props.inAppProduct.maxReimbursment}€{' '}
          </Text>
          de
          <br />
          remboursements avec
        </Text>
      </div>

      <div className="bg-white relative rounded-b-2xl">
        <div
          className={`border-2 flex pl-5 pr-9 py-5 rounded-b-2xl`}
          style={{ borderColor: props.inAppProduct.color, color: props.inAppProduct.color }}
        >
          <div className="flex-1 mr-5">
            <Svg alt={`Logo ${props.inAppProduct.label}`} height={88} src={props.inAppProduct.icon} width={88} />
          </div>

          <div>
            <Text size={20} weight="extrabold">
              {props.inAppProduct.label}
            </Text>
            <Text weight="semibold">pour {formatCentsAmountEuro(props.inAppProduct.price)}&nbsp;/&nbsp;mois</Text>
            <Button
              color={props.inAppProduct.color}
              onClick={() => {
                postMessageTrack({
                  name: 'consulte_detail_formule',
                  gamme: 'mybooster',
                  produit: props.inAppProduct.label,
                })
                props.onClick()
              }}
              text="Découvrir"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
