const amountFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
})

export function formatCentsAmount(amount: number) {
  return Math.round((amount * 100) / 100) / 100
}

export function formatCentsAmountEuro(amount: number) {
  return (
    amountFormatter
      .format(amount / 100)
      // format() is returning non-breaking spaces that breaks when rendering PDFs so we replace them with regular spaces
      // See https://stackoverflow.com/a/64909632/534838
      .replace(/\xa0/g, ' ')
      .replace(/\u202f/g, ' ')
  )
}
