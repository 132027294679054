import { docs } from '../utils/consts'
import { postMessageTrack } from '../utils/iframeUtils'
import Button from './Button'
import Section from './Section'

type Props = {
  source: 'Home' | 'Simulation'
}

export default function OpenDocumentationButton(props: Props) {
  return (
    <Section>
      <Button
        external={true}
        href={docs.documentation}
        onClick={() => postMessageTrack({ name: 'Demande une documentation', source: props.source })}
        text="Télécharger la documentation"
      />
    </Section>
  )
}
