import line51DentalCareSvg from './careExample/line5/1dentalCare.svg'
import line52OrthodonticsSvg from './careExample/line5/2orthodontics.svg'
import line53ImplantsSvg from './careExample/line5/3implants.svg'
import line54ProthesisSvg from './careExample/line5/4prothesis.svg'
import line1SpecialistSvg from './careExample/line1/specialist.svg'
import line21CareSvg from './careExample/line2/1care.svg'
import line22ImagingSvg from './careExample/line2/2imaging.svg'
import line23PhysioSvg from './careExample/line2/3physio.svg'
import line24TechnicalSvg from './careExample/line2/4technical.svg'
import line3AlternativeSvg from './careExample/line3/alternative.svg'
import line4ToothSvg from './careExample/line4/tooth.svg'
import Button from '../../../Button'
import Heading from '../../../Heading'
import Section from '../../../Section'
import Text from '../../../Text'
import IndexProductCard from './IndexProductCard'
import mySofieIconSvg from '../mysofie-logo.svg'
import Svg from '../../../Svg'
import checkSvg from './check.svg'
import alternativeCareSvg from './summary/alternativeCare.svg'
import dentalCareSvg from './summary/dentalCare.svg'
import generalCareSvg from './summary/generalCare.svg'
import classNames from 'classnames'
import { Fragment } from 'react'
import { IN_APP_PRODUCTS } from '../../../../utils/consts'
import { InAppProduct } from '../../../../types/in-app/InAppProduct'
import { postMessageOpenProductModal, postMessageTrack } from '../../../../utils/iframeUtils'
import { useSelector } from 'react-redux'
import { discountSelector } from '../../../../redux/selectors/discountSelector'
import routes from '../../../../utils/routes'
import OpenDocumentationButton from '../../../OpenDocumentationButton'

export function IndexPage() {
  const discount = useSelector(discountSelector)

  const onInfoClick = (product: InAppProduct) => postMessageOpenProductModal(product)

  return (
    <>
      <Section color="pink" itemsCenter={true}>
        <Svg alt="MySofie icon" height={70} src={mySofieIconSvg} width={114} />
        <Heading color="white" align="center">
          Pour que votre santé
          <br />
          n&apos;affecte pas{' '}
          <Text as="span" color="yellow">
            votre budget
          </Text>
        </Heading>

        <div className="pb-8 max-w-md">
          <Text align="center" color="white" size={20} weight="light">
            choisissez l’offre santé{' '}
            <Text as="span" weight="bold">
              qui s’adapte automatiquement à votre mutuelle
            </Text>{' '}
            pour réduire votre reste à charge
          </Text>
        </div>

        <ul className="list-none">
          {[
            'Pas besoin de changer de mutuelle',
            'Zéro facture / Zéro papier',
            'Remboursement automatique',
            "Visualisation des soins dans l'application",
          ].map((line) => (
            <li key={line} className="flex mb-2">
              <div className="mr-5">{<Svg alt="" height={21} src={checkSvg} width={19} />}</div>

              <Text color="white">{line}</Text>
            </li>
          ))}
        </ul>

        <StartQuoteButton />
      </Section>

      {discount.preface.insert && (
        <Section color="blueLight" itemsCenter={true}>
          <Text align="center" color="greyDarker" size={24} weight="semibold">
            <span dangerouslySetInnerHTML={{ __html: discount.preface.insert }} />
          </Text>
        </Section>
      )}

      <div className="bg-yellow pb-8">
        <Section itemsCenter={true}>
          <Heading color="white" align="center">
            <Text>Améliorez vos</Text>
            <Text color="pink">remboursements santé</Text>
          </Heading>
        </Section>
      </div>

      <div className="bg-white">
        <div className="-mb-12 relative -top-12 z-10">
          <Section itemsCenter={true}>
            {IN_APP_PRODUCTS.map((product) => (
              <div className="mb-2" key={product.label}>
                <IndexProductCard onClick={() => onInfoClick(product)} inAppProduct={product} />
              </div>
            ))}
          </Section>
        </div>
      </div>

      <div className="-top-16 pt-12 pb-5 relative bg-pink">
        <Section itemsCenter={true}>
          <Heading color="white" align="center">
            En{' '}
            <Text as="span" color="yellow">
              résumé
            </Text>
          </Heading>

          <div className="mb-7">
            <Text align="center" color="white">
              Choisissez votre pourcentage de
              <br />
              remboursement et les soins
            </Text>
          </div>

          <div
            className="align-middle grid mb-7 gap-2 text-xs sm:text-base"
            style={{ gridTemplateColumns: 'auto 1fr' }}
          >
            {IN_APP_PRODUCTS.map((product) => (
              <Fragment key={product.id}>
                <div className="flex items-center">
                  <div className="mr-2">
                    <Text color="white" weight="bold">
                      {product.label}
                    </Text>
                  </div>
                </div>

                <div className="flex items-center">
                  {[
                    { alt: 'Médecine de ville', color: 'bg-yellow', icon: generalCareSvg, kind: 'general' },
                    { alt: 'Médecine douce', color: 'bg-greenLight', icon: alternativeCareSvg, kind: 'alternative' },
                    { alt: 'Soins dentaires', color: 'bg-greenDark', icon: dentalCareSvg, kind: 'dental' },
                  ]
                    .filter((care) => care.kind !== 'dental' || (product.isDentalCare && care.kind === 'dental'))
                    .map((care) => (
                      <div
                        className={classNames('mr-1 flex flex-col items-center pb-0 pt-2', care.color, {
                          'w-9': product.careValue === 50,
                          'w-12 ': product.careValue !== 50,
                        })}
                        key={care.alt}
                      >
                        <Svg alt={care.alt} height={0} src={care.icon} width={0} />
                      </div>
                    ))}

                  <Text as="span" color="white" weight="bold">
                    {product.careValue}%
                    <Text as="span" weight="light">
                      *
                    </Text>
                  </Text>
                </div>
              </Fragment>
            ))}
          </div>

          <div className="mb-7">
            <Text color="white">* de votre reste à charge</Text>
          </div>
        </Section>
      </div>

      <div className="relative -top-28 z-10">
        <OpenDocumentationButton source="Home" />
      </div>

      <div className="bg-blueMedium pb-7 pt-7 relative -top-40">
        <Section itemsCenter={true}>
          <Heading color="white" align="center">
            Les 9 catégories de soins**&nbsp;:
          </Heading>

          <div className="mb-6 mx-auto max-w-md px-3">
            <Text color="white" weight="medium" size={15}>
              Jusqu’à 150€ de remboursement sur chacune des catégories de l&apos;offre choisie, par personne
            </Text>
            <Text align="center" color="white" size={15} fontStyle="italic">
              (avec un engagement de 12 mois)
            </Text>
          </div>
        </Section>

        <div className="mb-3">
          <Section itemsCenter={true}>
            <Svg alt="Spécialiste" height={29} src={line1SpecialistSvg} width={24} />

            <div className="mb-4">
              <Text color="yellow" size={24} weight="bold">
                Médecine de ville
              </Text>
              <Text color="white" align="center" size={14}>
                4 catégories de soins:
              </Text>
            </div>

            <CareIcons
              items={[
                { labels: ['Soins', 'courants'], height: 39, width: 38, src: line21CareSvg },
                { labels: ['Actes', 'd’imagerie'], height: 40, width: 27, src: line22ImagingSvg },
                { labels: ['Kiné', 'Infirmière'], height: 41, width: 40, src: line23PhysioSvg },
                { labels: ['Actes', 'Techniques'], height: 40, width: 25, src: line24TechnicalSvg },
              ]}
            />
          </Section>
        </div>

        <div className="mb-3">
          <Section itemsCenter={true}>
            <Svg alt="Médecine douce" height={25} src={line3AlternativeSvg} width={34} />
            <Text color="yellow" size={24} weight="bold">
              Médecine douce
            </Text>

            <Text color="yellow">Ostéopathie, sophrologie ...</Text>
            <Text color="white" align="center" size={14}>
              1 seule catégorie de soins
            </Text>
          </Section>
        </div>

        <div className="mb-3">
          <Section itemsCenter={true}>
            <Svg alt="Dentaire" height={20} src={line4ToothSvg} width={22} />

            <div className="mb-4 text-center">
              <Text color="yellow" size={24} weight="bold">
                Dentaire
              </Text>
              <Text color="white" align="center" size={14}>
                4 catégories de soins:
              </Text>
            </div>

            <div className="flex justify-between w-full">
              <CareIcons
                items={[
                  { labels: ['Soins', 'dentaires'], height: 42, width: 36, src: line51DentalCareSvg },
                  { labels: ['Ortho-', 'dontie'], height: 42, width: 39, src: line52OrthodonticsSvg },
                  { labels: ['Implanto', 'logie'], height: 45, width: 31, src: line53ImplantsSvg },
                  { labels: ['Prothèse', 'dentaire'], height: 45, width: 35, src: line54ProthesisSvg },
                ]}
              />
            </div>
          </Section>
        </div>

        <Text align="center" color="white" fontStyle="italic" size={15}>
          ** L&apos;optique n’est pas pris en charge{' '}
        </Text>
      </div>

      <div className="relative -top-36">
        <StartQuoteButton color="pink" />
      </div>
    </>
  )
}

const StartQuoteButton = (props: { color?: 'pink' }) => {
  return (
    <div className="w-full">
      <Section>
        <Button
          color={props.color}
          href={routes.step1SelectBeneficiaries}
          onClick={() => postMessageTrack({ name: 'Réalise son devis' })}
          text="Réaliser mon devis"
        />
      </Section>
    </div>
  )
}

type CareIconsProps = {
  items: { labels: string[]; height: number; width: number; src: string }[]
}

const CareIcons = (props: CareIconsProps) => (
  <div className="grid items-end w-full" style={{ gridTemplateColumns: `repeat(${props.items.length}, 1fr)` }}>
    {props.items.map((item) => {
      const label = item.labels.join('')

      return (
        <div className="flex flex-col items-center text-sm sm:text-lg " key={label}>
          <Svg alt={label} height={item.height} src={item.src} width={item.width} />
          {item.labels.map((label) => (
            <Text key={label} color="white" weight="semibold">
              {label}
            </Text>
          ))}
        </div>
      )
    })}
  </div>
)
